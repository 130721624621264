import { default as indexr9xAr1lvm8Meta } from "C:/IDE/projects/liga-portal/frontend/pages/2percenta-z-dani/index.vue?macro=true";
import { default as _91name_93YM15DTt890Meta } from "C:/IDE/projects/liga-portal/frontend/pages/archiv/[name].vue?macro=true";
import { default as indexNIBnU3kAKeMeta } from "C:/IDE/projects/liga-portal/frontend/pages/archiv/index.vue?macro=true";
import { default as _91name_93049Yp8D8YxMeta } from "C:/IDE/projects/liga-portal/frontend/pages/clanok/[name].vue?macro=true";
import { default as indexqdjA8BQ1MsMeta } from "C:/IDE/projects/liga-portal/frontend/pages/clanok/index.vue?macro=true";
import { default as _91name_93E767MsjqfzMeta } from "C:/IDE/projects/liga-portal/frontend/pages/diskusia/[name].vue?macro=true";
import { default as indexLjIjPBxdyLMeta } from "C:/IDE/projects/liga-portal/frontend/pages/diskusia/index.vue?macro=true";
import { default as duhova_45toleranciaP344Omy9i9Meta } from "C:/IDE/projects/liga-portal/frontend/pages/diskusie/duhova-tolerancia.vue?macro=true";
import { default as indexXt9jmYEKvXMeta } from "C:/IDE/projects/liga-portal/frontend/pages/diskusie/index.vue?macro=true";
import { default as indexr4BUkVb1xUMeta } from "C:/IDE/projects/liga-portal/frontend/pages/e-mailova-poradna-linky-dovery-nezabudka/index.vue?macro=true";
import { default as indexNk6DhNsbyQMeta } from "C:/IDE/projects/liga-portal/frontend/pages/error404/index.vue?macro=true";
import { default as _91name_93Ak0oU3l1H6Meta } from "C:/IDE/projects/liga-portal/frontend/pages/event-nezabudka/[name].vue?macro=true";
import { default as indexggwcE35GsVMeta } from "C:/IDE/projects/liga-portal/frontend/pages/event-nezabudka/index.vue?macro=true";
import { default as indexlhgNGsGunGMeta } from "C:/IDE/projects/liga-portal/frontend/pages/index.vue?macro=true";
import { default as _91name_93sh4PVEXCTvMeta } from "C:/IDE/projects/liga-portal/frontend/pages/kampan/[name].vue?macro=true";
import { default as indexXPa8AChPSFMeta } from "C:/IDE/projects/liga-portal/frontend/pages/kampan/index.vue?macro=true";
import { default as dobro4R6tg7ERgGMeta } from "C:/IDE/projects/liga-portal/frontend/pages/kampane/dobro.vue?macro=true";
import { default as index52uj5621dZMeta } from "C:/IDE/projects/liga-portal/frontend/pages/kampane/index.vue?macro=true";
import { default as _91type_93QrFMzZg8D8Meta } from "C:/IDE/projects/liga-portal/frontend/pages/kategoria/[name]/[type].vue?macro=true";
import { default as indexiDS4EqHVUfMeta } from "C:/IDE/projects/liga-portal/frontend/pages/kategoria/[name]/index.vue?macro=true";
import { default as _91name_93DDMWq122duMeta } from "C:/IDE/projects/liga-portal/frontend/pages/kurz/[name].vue?macro=true";
import { default as indexkSNa3hm7z0Meta } from "C:/IDE/projects/liga-portal/frontend/pages/kurz/index.vue?macro=true";
import { default as _91name_93vBcqGgX2eTMeta } from "C:/IDE/projects/liga-portal/frontend/pages/letak/[name].vue?macro=true";
import { default as indexEQCqorduVoMeta } from "C:/IDE/projects/liga-portal/frontend/pages/letak/index.vue?macro=true";
import { default as indexkWLTsBBYe9Meta } from "C:/IDE/projects/liga-portal/frontend/pages/mapa-podpory/index.vue?macro=true";
import { default as clenovia_45ligyF7gbd4ThhAMeta } from "C:/IDE/projects/liga-portal/frontend/pages/o-nas/clenovia-ligy.vue?macro=true";
import { default as eticke_45kodexyJGxjFmLOZBMeta } from "C:/IDE/projects/liga-portal/frontend/pages/o-nas/eticke-kodexy.vue?macro=true";
import { default as kontaktyjjCiLUZiljMeta } from "C:/IDE/projects/liga-portal/frontend/pages/o-nas/kontakty.vue?macro=true";
import { default as kto_45sme9zlqZlEY0qMeta } from "C:/IDE/projects/liga-portal/frontend/pages/o-nas/kto-sme.vue?macro=true";
import { default as partneri_45a_45donoriJ5GaqSTeHPMeta } from "C:/IDE/projects/liga-portal/frontend/pages/o-nas/partneri-a-donori.vue?macro=true";
import { default as pre_45media4HbYeTxRrXMeta } from "C:/IDE/projects/liga-portal/frontend/pages/o-nas/pre-media.vue?macro=true";
import { default as stanoviska_45ligySzOJA49qabMeta } from "C:/IDE/projects/liga-portal/frontend/pages/o-nas/stanoviska-ligy.vue?macro=true";
import { default as vyrocne_45spravyRHYixCxT02Meta } from "C:/IDE/projects/liga-portal/frontend/pages/o-nas/vyrocne-spravy.vue?macro=true";
import { default as index59bXm6MzZfMeta } from "C:/IDE/projects/liga-portal/frontend/pages/ocenenie-dobre-kruhy/index.vue?macro=true";
import { default as indexLEX62oAdKzMeta } from "C:/IDE/projects/liga-portal/frontend/pages/ochrana-osobnych-udajov/index.vue?macro=true";
import { default as _91name_931nKPgH5qWPMeta } from "C:/IDE/projects/liga-portal/frontend/pages/organizacia/[name].vue?macro=true";
import { default as index5abE8hvPhuMeta } from "C:/IDE/projects/liga-portal/frontend/pages/otazky-a-odpovede/index.vue?macro=true";
import { default as _91name_93UgSX4OFvazMeta } from "C:/IDE/projects/liga-portal/frontend/pages/peerovia/[name].vue?macro=true";
import { default as indexEw5H6cHKT1Meta } from "C:/IDE/projects/liga-portal/frontend/pages/peerovia/index.vue?macro=true";
import { default as _91name_93wjzFQBi2MnMeta } from "C:/IDE/projects/liga-portal/frontend/pages/podcast/[name].vue?macro=true";
import { default as indexXrL18laBJfMeta } from "C:/IDE/projects/liga-portal/frontend/pages/podcast/index.vue?macro=true";
import { default as index85GpPLQj0nMeta } from "C:/IDE/projects/liga-portal/frontend/pages/podporte-nas/index.vue?macro=true";
import { default as _91name_93yypMoAMYxIMeta } from "C:/IDE/projects/liga-portal/frontend/pages/podujatie/[name].vue?macro=true";
import { default as indexpadHABXPtuMeta } from "C:/IDE/projects/liga-portal/frontend/pages/podujatie/index.vue?macro=true";
import { default as slavnostny_45koncertcYrzWeYmQBMeta } from "C:/IDE/projects/liga-portal/frontend/pages/podujatie/slavnostny-koncert.vue?macro=true";
import { default as index14VtendHapMeta } from "C:/IDE/projects/liga-portal/frontend/pages/potrebujem-pomoc/index.vue?macro=true";
import { default as indexZqGJg6pHwYMeta } from "C:/IDE/projects/liga-portal/frontend/pages/preview/index.vue?macro=true";
import { default as _91name_93UASQANBL45Meta } from "C:/IDE/projects/liga-portal/frontend/pages/projekt/[name].vue?macro=true";
import { default as indexeVFx5vuePzMeta } from "C:/IDE/projects/liga-portal/frontend/pages/projekt/index.vue?macro=true";
import { default as indexTmKa7Qi9OVMeta } from "C:/IDE/projects/liga-portal/frontend/pages/publikacie/index.vue?macro=true";
import { default as index44CrP3ud3uMeta } from "C:/IDE/projects/liga-portal/frontend/pages/staznosti-a-spatna-vazba/index.vue?macro=true";
import { default as _91name_93SbSUJfrcYTMeta } from "C:/IDE/projects/liga-portal/frontend/pages/stranka/[name].vue?macro=true";
import { default as indexg2xrWsVAY7Meta } from "C:/IDE/projects/liga-portal/frontend/pages/stranka/index.vue?macro=true";
import { default as indexuO2yHTxrMIMeta } from "C:/IDE/projects/liga-portal/frontend/pages/testy/index.vue?macro=true";
import { default as _91name_93vFE9oJsjHuMeta } from "C:/IDE/projects/liga-portal/frontend/pages/video/[name].vue?macro=true";
import { default as indexBqGJtJedJ6Meta } from "C:/IDE/projects/liga-portal/frontend/pages/video/index.vue?macro=true";
import { default as _91categorySlug_93EEYTqOFLXbMeta } from "C:/IDE/projects/liga-portal/frontend/pages/vsetky-vysledky/[slug]/[categorySlug].vue?macro=true";
import { default as _91searchText_93lDCtFT5za7Meta } from "C:/IDE/projects/liga-portal/frontend/pages/vyhladavanie/[searchText].vue?macro=true";
import { default as _91name_93UyaWBiZLHgMeta } from "C:/IDE/projects/liga-portal/frontend/pages/vyzva/[name].vue?macro=true";
import { default as indexMrZnqQUl4HMeta } from "C:/IDE/projects/liga-portal/frontend/pages/vyzva/index.vue?macro=true";
import { default as indexFFETACFLT5Meta } from "C:/IDE/projects/liga-portal/frontend/pages/vyzvy/index.vue?macro=true";
import { default as indexb2MiW4LtQaMeta } from "C:/IDE/projects/liga-portal/frontend/pages/zbierka-dni-nezabudiek/index.vue?macro=true";
export default [
  {
    name: indexr9xAr1lvm8Meta?.name ?? "2percenta-z-dani",
    path: indexr9xAr1lvm8Meta?.path ?? "/2percenta-z-dani",
    meta: indexr9xAr1lvm8Meta || {},
    alias: indexr9xAr1lvm8Meta?.alias || [],
    redirect: indexr9xAr1lvm8Meta?.redirect || undefined,
    component: () => import("C:/IDE/projects/liga-portal/frontend/pages/2percenta-z-dani/index.vue").then(m => m.default || m)
  },
  {
    name: _91name_93YM15DTt890Meta?.name ?? "archiv-name",
    path: _91name_93YM15DTt890Meta?.path ?? "/archiv/:name()",
    meta: _91name_93YM15DTt890Meta || {},
    alias: _91name_93YM15DTt890Meta?.alias || [],
    redirect: _91name_93YM15DTt890Meta?.redirect || undefined,
    component: () => import("C:/IDE/projects/liga-portal/frontend/pages/archiv/[name].vue").then(m => m.default || m)
  },
  {
    name: indexNIBnU3kAKeMeta?.name ?? "archiv",
    path: indexNIBnU3kAKeMeta?.path ?? "/archiv",
    meta: indexNIBnU3kAKeMeta || {},
    alias: indexNIBnU3kAKeMeta?.alias || [],
    redirect: indexNIBnU3kAKeMeta?.redirect || undefined,
    component: () => import("C:/IDE/projects/liga-portal/frontend/pages/archiv/index.vue").then(m => m.default || m)
  },
  {
    name: _91name_93049Yp8D8YxMeta?.name ?? "clanok-name",
    path: _91name_93049Yp8D8YxMeta?.path ?? "/clanok/:name()",
    meta: _91name_93049Yp8D8YxMeta || {},
    alias: _91name_93049Yp8D8YxMeta?.alias || [],
    redirect: _91name_93049Yp8D8YxMeta?.redirect || undefined,
    component: () => import("C:/IDE/projects/liga-portal/frontend/pages/clanok/[name].vue").then(m => m.default || m)
  },
  {
    name: indexqdjA8BQ1MsMeta?.name ?? "clanok",
    path: indexqdjA8BQ1MsMeta?.path ?? "/clanok",
    meta: indexqdjA8BQ1MsMeta || {},
    alias: indexqdjA8BQ1MsMeta?.alias || [],
    redirect: indexqdjA8BQ1MsMeta?.redirect || undefined,
    component: () => import("C:/IDE/projects/liga-portal/frontend/pages/clanok/index.vue").then(m => m.default || m)
  },
  {
    name: _91name_93E767MsjqfzMeta?.name ?? "diskusia-name",
    path: _91name_93E767MsjqfzMeta?.path ?? "/diskusia/:name()",
    meta: _91name_93E767MsjqfzMeta || {},
    alias: _91name_93E767MsjqfzMeta?.alias || [],
    redirect: _91name_93E767MsjqfzMeta?.redirect || undefined,
    component: () => import("C:/IDE/projects/liga-portal/frontend/pages/diskusia/[name].vue").then(m => m.default || m)
  },
  {
    name: indexLjIjPBxdyLMeta?.name ?? "diskusia",
    path: indexLjIjPBxdyLMeta?.path ?? "/diskusia",
    meta: indexLjIjPBxdyLMeta || {},
    alias: indexLjIjPBxdyLMeta?.alias || [],
    redirect: indexLjIjPBxdyLMeta?.redirect || undefined,
    component: () => import("C:/IDE/projects/liga-portal/frontend/pages/diskusia/index.vue").then(m => m.default || m)
  },
  {
    name: duhova_45toleranciaP344Omy9i9Meta?.name ?? "diskusie-duhova-tolerancia",
    path: duhova_45toleranciaP344Omy9i9Meta?.path ?? "/diskusie/duhova-tolerancia",
    meta: duhova_45toleranciaP344Omy9i9Meta || {},
    alias: duhova_45toleranciaP344Omy9i9Meta?.alias || [],
    redirect: duhova_45toleranciaP344Omy9i9Meta?.redirect || undefined,
    component: () => import("C:/IDE/projects/liga-portal/frontend/pages/diskusie/duhova-tolerancia.vue").then(m => m.default || m)
  },
  {
    name: indexXt9jmYEKvXMeta?.name ?? "diskusie",
    path: indexXt9jmYEKvXMeta?.path ?? "/diskusie",
    meta: indexXt9jmYEKvXMeta || {},
    alias: indexXt9jmYEKvXMeta?.alias || [],
    redirect: indexXt9jmYEKvXMeta?.redirect || undefined,
    component: () => import("C:/IDE/projects/liga-portal/frontend/pages/diskusie/index.vue").then(m => m.default || m)
  },
  {
    name: indexr4BUkVb1xUMeta?.name ?? "e-mailova-poradna-linky-dovery-nezabudka",
    path: indexr4BUkVb1xUMeta?.path ?? "/e-mailova-poradna-linky-dovery-nezabudka",
    meta: indexr4BUkVb1xUMeta || {},
    alias: indexr4BUkVb1xUMeta?.alias || [],
    redirect: indexr4BUkVb1xUMeta?.redirect || undefined,
    component: () => import("C:/IDE/projects/liga-portal/frontend/pages/e-mailova-poradna-linky-dovery-nezabudka/index.vue").then(m => m.default || m)
  },
  {
    name: indexNk6DhNsbyQMeta?.name ?? "error404",
    path: indexNk6DhNsbyQMeta?.path ?? "/error404",
    meta: indexNk6DhNsbyQMeta || {},
    alias: indexNk6DhNsbyQMeta?.alias || [],
    redirect: indexNk6DhNsbyQMeta?.redirect || undefined,
    component: () => import("C:/IDE/projects/liga-portal/frontend/pages/error404/index.vue").then(m => m.default || m)
  },
  {
    name: _91name_93Ak0oU3l1H6Meta?.name ?? "event-nezabudka-name",
    path: _91name_93Ak0oU3l1H6Meta?.path ?? "/event-nezabudka/:name()",
    meta: _91name_93Ak0oU3l1H6Meta || {},
    alias: _91name_93Ak0oU3l1H6Meta?.alias || [],
    redirect: _91name_93Ak0oU3l1H6Meta?.redirect || undefined,
    component: () => import("C:/IDE/projects/liga-portal/frontend/pages/event-nezabudka/[name].vue").then(m => m.default || m)
  },
  {
    name: indexggwcE35GsVMeta?.name ?? "event-nezabudka",
    path: indexggwcE35GsVMeta?.path ?? "/event-nezabudka",
    meta: indexggwcE35GsVMeta || {},
    alias: indexggwcE35GsVMeta?.alias || [],
    redirect: indexggwcE35GsVMeta?.redirect || undefined,
    component: () => import("C:/IDE/projects/liga-portal/frontend/pages/event-nezabudka/index.vue").then(m => m.default || m)
  },
  {
    name: indexlhgNGsGunGMeta?.name ?? "index",
    path: indexlhgNGsGunGMeta?.path ?? "/",
    meta: indexlhgNGsGunGMeta || {},
    alias: indexlhgNGsGunGMeta?.alias || [],
    redirect: indexlhgNGsGunGMeta?.redirect || undefined,
    component: () => import("C:/IDE/projects/liga-portal/frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91name_93sh4PVEXCTvMeta?.name ?? "kampan-name",
    path: _91name_93sh4PVEXCTvMeta?.path ?? "/kampan/:name()",
    meta: _91name_93sh4PVEXCTvMeta || {},
    alias: _91name_93sh4PVEXCTvMeta?.alias || [],
    redirect: _91name_93sh4PVEXCTvMeta?.redirect || undefined,
    component: () => import("C:/IDE/projects/liga-portal/frontend/pages/kampan/[name].vue").then(m => m.default || m)
  },
  {
    name: indexXPa8AChPSFMeta?.name ?? "kampan",
    path: indexXPa8AChPSFMeta?.path ?? "/kampan",
    meta: indexXPa8AChPSFMeta || {},
    alias: indexXPa8AChPSFMeta?.alias || [],
    redirect: indexXPa8AChPSFMeta?.redirect || undefined,
    component: () => import("C:/IDE/projects/liga-portal/frontend/pages/kampan/index.vue").then(m => m.default || m)
  },
  {
    name: dobro4R6tg7ERgGMeta?.name ?? "kampane-dobro",
    path: dobro4R6tg7ERgGMeta?.path ?? "/kampane/dobro",
    meta: dobro4R6tg7ERgGMeta || {},
    alias: dobro4R6tg7ERgGMeta?.alias || [],
    redirect: dobro4R6tg7ERgGMeta?.redirect || undefined,
    component: () => import("C:/IDE/projects/liga-portal/frontend/pages/kampane/dobro.vue").then(m => m.default || m)
  },
  {
    name: index52uj5621dZMeta?.name ?? "kampane",
    path: index52uj5621dZMeta?.path ?? "/kampane",
    meta: index52uj5621dZMeta || {},
    alias: index52uj5621dZMeta?.alias || [],
    redirect: index52uj5621dZMeta?.redirect || undefined,
    component: () => import("C:/IDE/projects/liga-portal/frontend/pages/kampane/index.vue").then(m => m.default || m)
  },
  {
    name: _91type_93QrFMzZg8D8Meta?.name ?? "kategoria-name-type",
    path: _91type_93QrFMzZg8D8Meta?.path ?? "/kategoria/:name()/:type()",
    meta: _91type_93QrFMzZg8D8Meta || {},
    alias: _91type_93QrFMzZg8D8Meta?.alias || [],
    redirect: _91type_93QrFMzZg8D8Meta?.redirect || undefined,
    component: () => import("C:/IDE/projects/liga-portal/frontend/pages/kategoria/[name]/[type].vue").then(m => m.default || m)
  },
  {
    name: indexiDS4EqHVUfMeta?.name ?? "kategoria-name",
    path: indexiDS4EqHVUfMeta?.path ?? "/kategoria/:name()",
    meta: indexiDS4EqHVUfMeta || {},
    alias: indexiDS4EqHVUfMeta?.alias || [],
    redirect: indexiDS4EqHVUfMeta?.redirect || undefined,
    component: () => import("C:/IDE/projects/liga-portal/frontend/pages/kategoria/[name]/index.vue").then(m => m.default || m)
  },
  {
    name: _91name_93DDMWq122duMeta?.name ?? "kurz-name",
    path: _91name_93DDMWq122duMeta?.path ?? "/kurz/:name()",
    meta: _91name_93DDMWq122duMeta || {},
    alias: _91name_93DDMWq122duMeta?.alias || [],
    redirect: _91name_93DDMWq122duMeta?.redirect || undefined,
    component: () => import("C:/IDE/projects/liga-portal/frontend/pages/kurz/[name].vue").then(m => m.default || m)
  },
  {
    name: indexkSNa3hm7z0Meta?.name ?? "kurz",
    path: indexkSNa3hm7z0Meta?.path ?? "/kurz",
    meta: indexkSNa3hm7z0Meta || {},
    alias: indexkSNa3hm7z0Meta?.alias || [],
    redirect: indexkSNa3hm7z0Meta?.redirect || undefined,
    component: () => import("C:/IDE/projects/liga-portal/frontend/pages/kurz/index.vue").then(m => m.default || m)
  },
  {
    name: _91name_93vBcqGgX2eTMeta?.name ?? "letak-name",
    path: _91name_93vBcqGgX2eTMeta?.path ?? "/letak/:name()",
    meta: _91name_93vBcqGgX2eTMeta || {},
    alias: _91name_93vBcqGgX2eTMeta?.alias || [],
    redirect: _91name_93vBcqGgX2eTMeta?.redirect || undefined,
    component: () => import("C:/IDE/projects/liga-portal/frontend/pages/letak/[name].vue").then(m => m.default || m)
  },
  {
    name: indexEQCqorduVoMeta?.name ?? "letak",
    path: indexEQCqorduVoMeta?.path ?? "/letak",
    meta: indexEQCqorduVoMeta || {},
    alias: indexEQCqorduVoMeta?.alias || [],
    redirect: indexEQCqorduVoMeta?.redirect || undefined,
    component: () => import("C:/IDE/projects/liga-portal/frontend/pages/letak/index.vue").then(m => m.default || m)
  },
  {
    name: indexkWLTsBBYe9Meta?.name ?? "mapa-podpory",
    path: indexkWLTsBBYe9Meta?.path ?? "/mapa-podpory",
    meta: indexkWLTsBBYe9Meta || {},
    alias: indexkWLTsBBYe9Meta?.alias || [],
    redirect: indexkWLTsBBYe9Meta?.redirect || undefined,
    component: () => import("C:/IDE/projects/liga-portal/frontend/pages/mapa-podpory/index.vue").then(m => m.default || m)
  },
  {
    name: clenovia_45ligyF7gbd4ThhAMeta?.name ?? "o-nas-clenovia-ligy",
    path: clenovia_45ligyF7gbd4ThhAMeta?.path ?? "/o-nas/clenovia-ligy",
    meta: clenovia_45ligyF7gbd4ThhAMeta || {},
    alias: clenovia_45ligyF7gbd4ThhAMeta?.alias || [],
    redirect: clenovia_45ligyF7gbd4ThhAMeta?.redirect || undefined,
    component: () => import("C:/IDE/projects/liga-portal/frontend/pages/o-nas/clenovia-ligy.vue").then(m => m.default || m)
  },
  {
    name: eticke_45kodexyJGxjFmLOZBMeta?.name ?? "o-nas-eticke-kodexy",
    path: eticke_45kodexyJGxjFmLOZBMeta?.path ?? "/o-nas/eticke-kodexy",
    meta: eticke_45kodexyJGxjFmLOZBMeta || {},
    alias: eticke_45kodexyJGxjFmLOZBMeta?.alias || [],
    redirect: eticke_45kodexyJGxjFmLOZBMeta?.redirect || undefined,
    component: () => import("C:/IDE/projects/liga-portal/frontend/pages/o-nas/eticke-kodexy.vue").then(m => m.default || m)
  },
  {
    name: kontaktyjjCiLUZiljMeta?.name ?? "o-nas-kontakty",
    path: kontaktyjjCiLUZiljMeta?.path ?? "/o-nas/kontakty",
    meta: kontaktyjjCiLUZiljMeta || {},
    alias: kontaktyjjCiLUZiljMeta?.alias || [],
    redirect: kontaktyjjCiLUZiljMeta?.redirect || undefined,
    component: () => import("C:/IDE/projects/liga-portal/frontend/pages/o-nas/kontakty.vue").then(m => m.default || m)
  },
  {
    name: kto_45sme9zlqZlEY0qMeta?.name ?? "o-nas-kto-sme",
    path: kto_45sme9zlqZlEY0qMeta?.path ?? "/o-nas/kto-sme",
    meta: kto_45sme9zlqZlEY0qMeta || {},
    alias: kto_45sme9zlqZlEY0qMeta?.alias || [],
    redirect: kto_45sme9zlqZlEY0qMeta?.redirect || undefined,
    component: () => import("C:/IDE/projects/liga-portal/frontend/pages/o-nas/kto-sme.vue").then(m => m.default || m)
  },
  {
    name: partneri_45a_45donoriJ5GaqSTeHPMeta?.name ?? "o-nas-partneri-a-donori",
    path: partneri_45a_45donoriJ5GaqSTeHPMeta?.path ?? "/o-nas/partneri-a-donori",
    meta: partneri_45a_45donoriJ5GaqSTeHPMeta || {},
    alias: partneri_45a_45donoriJ5GaqSTeHPMeta?.alias || [],
    redirect: partneri_45a_45donoriJ5GaqSTeHPMeta?.redirect || undefined,
    component: () => import("C:/IDE/projects/liga-portal/frontend/pages/o-nas/partneri-a-donori.vue").then(m => m.default || m)
  },
  {
    name: pre_45media4HbYeTxRrXMeta?.name ?? "o-nas-pre-media",
    path: pre_45media4HbYeTxRrXMeta?.path ?? "/o-nas/pre-media",
    meta: pre_45media4HbYeTxRrXMeta || {},
    alias: pre_45media4HbYeTxRrXMeta?.alias || [],
    redirect: pre_45media4HbYeTxRrXMeta?.redirect || undefined,
    component: () => import("C:/IDE/projects/liga-portal/frontend/pages/o-nas/pre-media.vue").then(m => m.default || m)
  },
  {
    name: stanoviska_45ligySzOJA49qabMeta?.name ?? "o-nas-stanoviska-ligy",
    path: stanoviska_45ligySzOJA49qabMeta?.path ?? "/o-nas/stanoviska-ligy",
    meta: stanoviska_45ligySzOJA49qabMeta || {},
    alias: stanoviska_45ligySzOJA49qabMeta?.alias || [],
    redirect: stanoviska_45ligySzOJA49qabMeta?.redirect || undefined,
    component: () => import("C:/IDE/projects/liga-portal/frontend/pages/o-nas/stanoviska-ligy.vue").then(m => m.default || m)
  },
  {
    name: vyrocne_45spravyRHYixCxT02Meta?.name ?? "o-nas-vyrocne-spravy",
    path: vyrocne_45spravyRHYixCxT02Meta?.path ?? "/o-nas/vyrocne-spravy",
    meta: vyrocne_45spravyRHYixCxT02Meta || {},
    alias: vyrocne_45spravyRHYixCxT02Meta?.alias || [],
    redirect: vyrocne_45spravyRHYixCxT02Meta?.redirect || undefined,
    component: () => import("C:/IDE/projects/liga-portal/frontend/pages/o-nas/vyrocne-spravy.vue").then(m => m.default || m)
  },
  {
    name: index59bXm6MzZfMeta?.name ?? "ocenenie-dobre-kruhy",
    path: index59bXm6MzZfMeta?.path ?? "/ocenenie-dobre-kruhy",
    meta: index59bXm6MzZfMeta || {},
    alias: index59bXm6MzZfMeta?.alias || [],
    redirect: index59bXm6MzZfMeta?.redirect || undefined,
    component: () => import("C:/IDE/projects/liga-portal/frontend/pages/ocenenie-dobre-kruhy/index.vue").then(m => m.default || m)
  },
  {
    name: indexLEX62oAdKzMeta?.name ?? "ochrana-osobnych-udajov",
    path: indexLEX62oAdKzMeta?.path ?? "/ochrana-osobnych-udajov",
    meta: indexLEX62oAdKzMeta || {},
    alias: indexLEX62oAdKzMeta?.alias || [],
    redirect: indexLEX62oAdKzMeta?.redirect || undefined,
    component: () => import("C:/IDE/projects/liga-portal/frontend/pages/ochrana-osobnych-udajov/index.vue").then(m => m.default || m)
  },
  {
    name: _91name_931nKPgH5qWPMeta?.name ?? "organizacia-name",
    path: _91name_931nKPgH5qWPMeta?.path ?? "/organizacia/:name()",
    meta: _91name_931nKPgH5qWPMeta || {},
    alias: _91name_931nKPgH5qWPMeta?.alias || [],
    redirect: _91name_931nKPgH5qWPMeta?.redirect || undefined,
    component: () => import("C:/IDE/projects/liga-portal/frontend/pages/organizacia/[name].vue").then(m => m.default || m)
  },
  {
    name: index5abE8hvPhuMeta?.name ?? "otazky-a-odpovede",
    path: index5abE8hvPhuMeta?.path ?? "/otazky-a-odpovede",
    meta: index5abE8hvPhuMeta || {},
    alias: index5abE8hvPhuMeta?.alias || [],
    redirect: index5abE8hvPhuMeta?.redirect || undefined,
    component: () => import("C:/IDE/projects/liga-portal/frontend/pages/otazky-a-odpovede/index.vue").then(m => m.default || m)
  },
  {
    name: _91name_93UgSX4OFvazMeta?.name ?? "peerovia-name",
    path: _91name_93UgSX4OFvazMeta?.path ?? "/peerovia/:name()",
    meta: _91name_93UgSX4OFvazMeta || {},
    alias: _91name_93UgSX4OFvazMeta?.alias || [],
    redirect: _91name_93UgSX4OFvazMeta?.redirect || undefined,
    component: () => import("C:/IDE/projects/liga-portal/frontend/pages/peerovia/[name].vue").then(m => m.default || m)
  },
  {
    name: indexEw5H6cHKT1Meta?.name ?? "peerovia",
    path: indexEw5H6cHKT1Meta?.path ?? "/peerovia",
    meta: indexEw5H6cHKT1Meta || {},
    alias: indexEw5H6cHKT1Meta?.alias || [],
    redirect: indexEw5H6cHKT1Meta?.redirect || undefined,
    component: () => import("C:/IDE/projects/liga-portal/frontend/pages/peerovia/index.vue").then(m => m.default || m)
  },
  {
    name: _91name_93wjzFQBi2MnMeta?.name ?? "podcast-name",
    path: _91name_93wjzFQBi2MnMeta?.path ?? "/podcast/:name()",
    meta: _91name_93wjzFQBi2MnMeta || {},
    alias: _91name_93wjzFQBi2MnMeta?.alias || [],
    redirect: _91name_93wjzFQBi2MnMeta?.redirect || undefined,
    component: () => import("C:/IDE/projects/liga-portal/frontend/pages/podcast/[name].vue").then(m => m.default || m)
  },
  {
    name: indexXrL18laBJfMeta?.name ?? "podcast",
    path: indexXrL18laBJfMeta?.path ?? "/podcast",
    meta: indexXrL18laBJfMeta || {},
    alias: indexXrL18laBJfMeta?.alias || [],
    redirect: indexXrL18laBJfMeta?.redirect || undefined,
    component: () => import("C:/IDE/projects/liga-portal/frontend/pages/podcast/index.vue").then(m => m.default || m)
  },
  {
    name: index85GpPLQj0nMeta?.name ?? "podporte-nas",
    path: index85GpPLQj0nMeta?.path ?? "/podporte-nas",
    meta: index85GpPLQj0nMeta || {},
    alias: index85GpPLQj0nMeta?.alias || [],
    redirect: index85GpPLQj0nMeta?.redirect || undefined,
    component: () => import("C:/IDE/projects/liga-portal/frontend/pages/podporte-nas/index.vue").then(m => m.default || m)
  },
  {
    name: _91name_93yypMoAMYxIMeta?.name ?? "podujatie-name",
    path: _91name_93yypMoAMYxIMeta?.path ?? "/podujatie/:name()",
    meta: _91name_93yypMoAMYxIMeta || {},
    alias: _91name_93yypMoAMYxIMeta?.alias || [],
    redirect: _91name_93yypMoAMYxIMeta?.redirect || undefined,
    component: () => import("C:/IDE/projects/liga-portal/frontend/pages/podujatie/[name].vue").then(m => m.default || m)
  },
  {
    name: indexpadHABXPtuMeta?.name ?? "podujatie",
    path: indexpadHABXPtuMeta?.path ?? "/podujatie",
    meta: indexpadHABXPtuMeta || {},
    alias: indexpadHABXPtuMeta?.alias || [],
    redirect: indexpadHABXPtuMeta?.redirect || undefined,
    component: () => import("C:/IDE/projects/liga-portal/frontend/pages/podujatie/index.vue").then(m => m.default || m)
  },
  {
    name: slavnostny_45koncertcYrzWeYmQBMeta?.name ?? "podujatie-slavnostny-koncert",
    path: slavnostny_45koncertcYrzWeYmQBMeta?.path ?? "/podujatie/slavnostny-koncert",
    meta: slavnostny_45koncertcYrzWeYmQBMeta || {},
    alias: slavnostny_45koncertcYrzWeYmQBMeta?.alias || [],
    redirect: slavnostny_45koncertcYrzWeYmQBMeta?.redirect || undefined,
    component: () => import("C:/IDE/projects/liga-portal/frontend/pages/podujatie/slavnostny-koncert.vue").then(m => m.default || m)
  },
  {
    name: index14VtendHapMeta?.name ?? "potrebujem-pomoc",
    path: index14VtendHapMeta?.path ?? "/potrebujem-pomoc",
    meta: index14VtendHapMeta || {},
    alias: index14VtendHapMeta?.alias || [],
    redirect: index14VtendHapMeta?.redirect || undefined,
    component: () => import("C:/IDE/projects/liga-portal/frontend/pages/potrebujem-pomoc/index.vue").then(m => m.default || m)
  },
  {
    name: indexZqGJg6pHwYMeta?.name ?? "preview",
    path: indexZqGJg6pHwYMeta?.path ?? "/preview",
    meta: indexZqGJg6pHwYMeta || {},
    alias: indexZqGJg6pHwYMeta?.alias || [],
    redirect: indexZqGJg6pHwYMeta?.redirect || undefined,
    component: () => import("C:/IDE/projects/liga-portal/frontend/pages/preview/index.vue").then(m => m.default || m)
  },
  {
    name: _91name_93UASQANBL45Meta?.name ?? "projekt-name",
    path: _91name_93UASQANBL45Meta?.path ?? "/projekt/:name()",
    meta: _91name_93UASQANBL45Meta || {},
    alias: _91name_93UASQANBL45Meta?.alias || [],
    redirect: _91name_93UASQANBL45Meta?.redirect || undefined,
    component: () => import("C:/IDE/projects/liga-portal/frontend/pages/projekt/[name].vue").then(m => m.default || m)
  },
  {
    name: indexeVFx5vuePzMeta?.name ?? "projekt",
    path: indexeVFx5vuePzMeta?.path ?? "/projekt",
    meta: indexeVFx5vuePzMeta || {},
    alias: indexeVFx5vuePzMeta?.alias || [],
    redirect: indexeVFx5vuePzMeta?.redirect || undefined,
    component: () => import("C:/IDE/projects/liga-portal/frontend/pages/projekt/index.vue").then(m => m.default || m)
  },
  {
    name: indexTmKa7Qi9OVMeta?.name ?? "publikacie",
    path: indexTmKa7Qi9OVMeta?.path ?? "/publikacie",
    meta: indexTmKa7Qi9OVMeta || {},
    alias: indexTmKa7Qi9OVMeta?.alias || [],
    redirect: indexTmKa7Qi9OVMeta?.redirect || undefined,
    component: () => import("C:/IDE/projects/liga-portal/frontend/pages/publikacie/index.vue").then(m => m.default || m)
  },
  {
    name: index44CrP3ud3uMeta?.name ?? "staznosti-a-spatna-vazba",
    path: index44CrP3ud3uMeta?.path ?? "/staznosti-a-spatna-vazba",
    meta: index44CrP3ud3uMeta || {},
    alias: index44CrP3ud3uMeta?.alias || [],
    redirect: index44CrP3ud3uMeta?.redirect || undefined,
    component: () => import("C:/IDE/projects/liga-portal/frontend/pages/staznosti-a-spatna-vazba/index.vue").then(m => m.default || m)
  },
  {
    name: _91name_93SbSUJfrcYTMeta?.name ?? "stranka-name",
    path: _91name_93SbSUJfrcYTMeta?.path ?? "/stranka/:name()",
    meta: _91name_93SbSUJfrcYTMeta || {},
    alias: _91name_93SbSUJfrcYTMeta?.alias || [],
    redirect: _91name_93SbSUJfrcYTMeta?.redirect || undefined,
    component: () => import("C:/IDE/projects/liga-portal/frontend/pages/stranka/[name].vue").then(m => m.default || m)
  },
  {
    name: indexg2xrWsVAY7Meta?.name ?? "stranka",
    path: indexg2xrWsVAY7Meta?.path ?? "/stranka",
    meta: indexg2xrWsVAY7Meta || {},
    alias: indexg2xrWsVAY7Meta?.alias || [],
    redirect: indexg2xrWsVAY7Meta?.redirect || undefined,
    component: () => import("C:/IDE/projects/liga-portal/frontend/pages/stranka/index.vue").then(m => m.default || m)
  },
  {
    name: indexuO2yHTxrMIMeta?.name ?? "testy",
    path: indexuO2yHTxrMIMeta?.path ?? "/testy",
    meta: indexuO2yHTxrMIMeta || {},
    alias: indexuO2yHTxrMIMeta?.alias || [],
    redirect: indexuO2yHTxrMIMeta?.redirect || undefined,
    component: () => import("C:/IDE/projects/liga-portal/frontend/pages/testy/index.vue").then(m => m.default || m)
  },
  {
    name: _91name_93vFE9oJsjHuMeta?.name ?? "video-name",
    path: _91name_93vFE9oJsjHuMeta?.path ?? "/video/:name()",
    meta: _91name_93vFE9oJsjHuMeta || {},
    alias: _91name_93vFE9oJsjHuMeta?.alias || [],
    redirect: _91name_93vFE9oJsjHuMeta?.redirect || undefined,
    component: () => import("C:/IDE/projects/liga-portal/frontend/pages/video/[name].vue").then(m => m.default || m)
  },
  {
    name: indexBqGJtJedJ6Meta?.name ?? "video",
    path: indexBqGJtJedJ6Meta?.path ?? "/video",
    meta: indexBqGJtJedJ6Meta || {},
    alias: indexBqGJtJedJ6Meta?.alias || [],
    redirect: indexBqGJtJedJ6Meta?.redirect || undefined,
    component: () => import("C:/IDE/projects/liga-portal/frontend/pages/video/index.vue").then(m => m.default || m)
  },
  {
    name: _91categorySlug_93EEYTqOFLXbMeta?.name ?? "vsetky-vysledky-slug-categorySlug",
    path: _91categorySlug_93EEYTqOFLXbMeta?.path ?? "/vsetky-vysledky/:slug()/:categorySlug()",
    meta: _91categorySlug_93EEYTqOFLXbMeta || {},
    alias: _91categorySlug_93EEYTqOFLXbMeta?.alias || [],
    redirect: _91categorySlug_93EEYTqOFLXbMeta?.redirect || undefined,
    component: () => import("C:/IDE/projects/liga-portal/frontend/pages/vsetky-vysledky/[slug]/[categorySlug].vue").then(m => m.default || m)
  },
  {
    name: _91searchText_93lDCtFT5za7Meta?.name ?? "vyhladavanie-searchText",
    path: _91searchText_93lDCtFT5za7Meta?.path ?? "/vyhladavanie/:searchText()",
    meta: _91searchText_93lDCtFT5za7Meta || {},
    alias: _91searchText_93lDCtFT5za7Meta?.alias || [],
    redirect: _91searchText_93lDCtFT5za7Meta?.redirect || undefined,
    component: () => import("C:/IDE/projects/liga-portal/frontend/pages/vyhladavanie/[searchText].vue").then(m => m.default || m)
  },
  {
    name: _91name_93UyaWBiZLHgMeta?.name ?? "vyzva-name",
    path: _91name_93UyaWBiZLHgMeta?.path ?? "/vyzva/:name()",
    meta: _91name_93UyaWBiZLHgMeta || {},
    alias: _91name_93UyaWBiZLHgMeta?.alias || [],
    redirect: _91name_93UyaWBiZLHgMeta?.redirect || undefined,
    component: () => import("C:/IDE/projects/liga-portal/frontend/pages/vyzva/[name].vue").then(m => m.default || m)
  },
  {
    name: indexMrZnqQUl4HMeta?.name ?? "vyzva",
    path: indexMrZnqQUl4HMeta?.path ?? "/vyzva",
    meta: indexMrZnqQUl4HMeta || {},
    alias: indexMrZnqQUl4HMeta?.alias || [],
    redirect: indexMrZnqQUl4HMeta?.redirect || undefined,
    component: () => import("C:/IDE/projects/liga-portal/frontend/pages/vyzva/index.vue").then(m => m.default || m)
  },
  {
    name: indexFFETACFLT5Meta?.name ?? "vyzvy",
    path: indexFFETACFLT5Meta?.path ?? "/vyzvy",
    meta: indexFFETACFLT5Meta || {},
    alias: indexFFETACFLT5Meta?.alias || [],
    redirect: indexFFETACFLT5Meta?.redirect || undefined,
    component: () => import("C:/IDE/projects/liga-portal/frontend/pages/vyzvy/index.vue").then(m => m.default || m)
  },
  {
    name: indexb2MiW4LtQaMeta?.name ?? "zbierka-dni-nezabudiek",
    path: indexb2MiW4LtQaMeta?.path ?? "/zbierka-dni-nezabudiek",
    meta: indexb2MiW4LtQaMeta || {},
    alias: indexb2MiW4LtQaMeta?.alias || [],
    redirect: indexb2MiW4LtQaMeta?.redirect || undefined,
    component: () => import("C:/IDE/projects/liga-portal/frontend/pages/zbierka-dni-nezabudiek/index.vue").then(m => m.default || m)
  },
  {
    name: _91categorySlug_93EEYTqOFLXbMeta?.name ?? "vsetky-vysledky",
    path: _91categorySlug_93EEYTqOFLXbMeta?.path ?? "/vsetky-vysledky/:slug",
    meta: _91categorySlug_93EEYTqOFLXbMeta || {},
    alias: _91categorySlug_93EEYTqOFLXbMeta?.alias || [],
    redirect: _91categorySlug_93EEYTqOFLXbMeta?.redirect || undefined,
    component: () => import("C:/IDE/projects/liga-portal/frontend/pages/vsetky-vysledky/[slug]/[categorySlug].vue").then(m => m.default || m)
  }
]